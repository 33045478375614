<template>
  <div class="home">
    <h1>QR Code Fab</h1>
    <h3>Quickly generate your own Wi-Fi QR code</h3>
    <div id="input-container">
        <div id="wifi-name" class="input-field">
            <span>Wi-Fi Name</span><br>
            <input v-model.lazy="wifiname" placeholder="wifi name">
        </div> 
        <div id="wifi-password" class="input-field">
            <span>Wi-Fi Password</span><br>
            <input v-model.lazy="wifipassword" placeholder="wifi password">
        </div> 

        <div id="generate-button">
            <button v-on:click="generate"> generate </button>
        </div>   

        <div class="output-container">
            <div id="canvas" ref="canvas"></div>   
            <p class="name-pw-display" v-if="isVisible">Wi-Fi: <strong>{{ display.name }}</strong><br>PW: <strong>{{ display.password }}</strong></p>
        </div>
    
        <div id="download-button">
            <button v-if="isVisible" v-on:click="download"> download </button>
        </div> 
         
    </div>
  </div>
</template>

<script>
import QRCodeStyling from "qr-code-styling"

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
        wifiname: "",
        wifipassword: "",
        qrcodecreated: false,
        isVisible: false,
        display: {
            name: "",
            password: ""
        },
        qrCode: null
    }
  },
  methods: {
      generate: function() {
          var concatInputString = this.wifiname.concat(this.wifipassword);
          console.log(concatInputString);

        // "WIFI:T:WPA;S:<SSID>;P:<PASSWORD>;;"
        this.qrCode = new QRCodeStyling({
            width: 300,
            height: 300,
            data: "WIFI:T:WPA;S:" + this.wifiname + ";P:" + this.wifipassword + ";;",
            // image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
            dotsOptions: {
                color: "#333",
                type: "square"
            },
            backgroundOptions: {
                color: "#fff",
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 20
            }
        });

        if(this.qrcodecreated == true){
           document.getElementById("canvas").innerHTML = "";
        }
        this.qrCode.append(document.getElementById("canvas"));
        this.qrcodecreated = true;
        this.isVisible = true;
        this.display.name = this.wifiname;
        this.display.password = this.wifipassword;
      },
      download: function(){
        // let downloadOptions = {
        //     name: "qrcode",
        //     extension: "jpeg"
        // }
        // qrCode.download(downloadOptions);
        this.qrCode.download();
      }
  }
}
</script>



<style>
.output-container {
    margin-top: 2em;
}

.input-field {
    margin-top: 1em;
}

#generate-button {
    margin-top: 1.5em;
}

h1 {
    margin-bottom: 0;
}

h3 {
    margin-top: 0;
}

</style>